import * as React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import shape21 from "../../assets/shape/shape-21.png";
import shape22 from "../../assets/shape/shape-22.png";
import "./Pricing.css";
import { generalPackages } from "../../data/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faChalkboardTeacher,
  faGraduationCap,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useState } from "react";

const ADMIN_BASE_URL = "https://admin.inspir-edu.com/register?step=1";

const Pricing = () => {
  const [showDiscount, setShowDiscount] = useState(false);
  const [animate, setAnimate] = useState(false);
  const pricingRef = React.useRef(null);

  const isMobile = window.innerWidth <= 768; // Detect mobile devices

  useEffect(() => {
    if (isMobile) {
      const handleScroll = () => {
        const pricingElement = pricingRef.current;
        if (pricingElement) {
          const rect = pricingElement.getBoundingClientRect();
          if (rect.top < window.innerHeight && rect.bottom >= 0) {
            setShowDiscount(true);
            setAnimate(true);
            setTimeout(() => {
              setAnimate(false);
            }, 1000);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setShowDiscount(true);
              setAnimate(true);
              setTimeout(() => {
                setAnimate(false);
              }, 1000);
            }
          });
        },
        { threshold: 0.5 } // Trigger when 50% of the element is visible
      );

      if (pricingRef.current) {
        observer.observe(pricingRef.current);
      }

      return () => {
        if (pricingRef.current) {
          observer.unobserve(pricingRef.current);
        }
      };
    }
  }, [isMobile]);

  const handleSubscribeClick = () => {
    window.open(ADMIN_BASE_URL, "_blank");
  };

  const calculateDiscountedPrice = (price) => {
    return (price * 0.5).toFixed(2);
  };

  return (
    <>
      <div
        id="pricing"
        ref={pricingRef}
        className="agency-pricing-area bg-color-f9f5f4 pt-100 pb-70 overflow-hidden"
      >
        <div className="container">
          <div className="agency-section-title">
            <h2>Choose the best plan for you</h2>
          </div>

          <div className="agency-pricing-tabs">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <Tabs>
                  <TabList>
                    <Tab>Monthly</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="row justify-content-center">
                      {/* Basic Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="200"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box">
                          <div className="pricing-content">
                            <div className="limited-time-offer">
                              Limited Time Offer
                            </div>
                            <h1
                              className={`heading ${
                                animate ? "splash-animation" : ""
                              }`}
                            >
                              <span className="original-price">
                                ${generalPackages.basicPackage.price}
                              </span>
                              $
                              {showDiscount || isMobile
                                ? calculateDiscountedPrice(
                                    generalPackages.basicPackage.price
                                  )
                                : generalPackages.basicPackage.price}{" "}
                              <span>/month</span>
                            </h1>
                            <h6>{generalPackages.basicPackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {generalPackages.basicPackage.suitableForBusiness}
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.basicPackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.basicPackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.basicPackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      {/* Advanced Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="400"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box">
                          <div className="pricing-content">
                            <div className="limited-time-offer">
                              Limited Time Offer
                            </div>
                            <h1
                              className={`heading ${
                                animate ? "splash-animation" : ""
                              }`}
                            >
                              <span className="original-price">
                                ${generalPackages.advancedPackage.price}
                              </span>
                              $
                              {showDiscount || isMobile
                                ? calculateDiscountedPrice(
                                    generalPackages.advancedPackage.price
                                  )
                                : generalPackages.advancedPackage.price}{" "}
                              <span>/month</span>
                            </h1>
                            <h6>{generalPackages.advancedPackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {
                                generalPackages.advancedPackage
                                  .suitableForBusiness
                              }
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.advancedPackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.advancedPackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.advancedPackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      {/* Premium Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="600"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box active">
                          <div className="pricing-content">
                            <div className="limited-time-offer">
                              Limited Time Offer
                            </div>
                            <h1
                              className={`heading ${
                                animate ? "splash-animation" : ""
                              }`}
                            >
                              <span className="original-price">
                                ${generalPackages.premiumPackage.price}
                              </span>
                              $
                              {showDiscount || isMobile
                                ? calculateDiscountedPrice(
                                    generalPackages.premiumPackage.price
                                  )
                                : generalPackages.premiumPackage.price}{" "}
                              <span>/month</span>
                            </h1>
                            <h6>{generalPackages.premiumPackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {
                                generalPackages.premiumPackage
                                  .suitableForBusiness
                              }
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.premiumPackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.premiumPackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.premiumPackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                            <div className="popular">MOST POPULAR</div>
                          </div>
                        </div>
                      </div>

                      {/* Enterprise Package */}
                      <div
                        className="col-lg-3 col-md-6"
                        data-aos="fade-up"
                        data-aos-duration="800"
                        data-aos-delay="800"
                        data-aos-once="true"
                      >
                        <div className="agency-pricing-box">
                          <div className="pricing-content">
                            <div className="limited-time-offer">
                              Limited Time Offer
                            </div>
                            <h1
                              className={`heading ${
                                animate ? "splash-animation" : ""
                              }`}
                            >
                              <span className="original-price">
                                ${generalPackages.enterprisePackage.price}
                              </span>
                              $
                              {showDiscount || isMobile
                                ? calculateDiscountedPrice(
                                    generalPackages.enterprisePackage.price
                                  )
                                : generalPackages.enterprisePackage.price}{" "}
                              <span>/month</span>
                            </h1>
                            <h6>{generalPackages.enterprisePackage.heading}</h6>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faBriefcase}
                                className="me-2"
                              />
                              {
                                generalPackages.enterprisePackage
                                  .suitableForBusiness
                              }
                            </p>
                            <p className="d-flex align-items-center">
                              <FontAwesomeIcon
                                icon={faGraduationCap}
                                className="me-2"
                              />
                              {
                                generalPackages.enterprisePackage
                                  .suitableForEducation
                              }
                            </p>
                            <div className="pricing-list">
                              <ul>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faUserFriends}
                                    className="me-2"
                                  />
                                  {generalPackages.enterprisePackage.persons}
                                </li>
                                <li className="d-flex align-items-center">
                                  <FontAwesomeIcon
                                    icon={faChalkboardTeacher}
                                    className="me-2"
                                  />
                                  {generalPackages.enterprisePackage.modules}
                                </li>
                              </ul>
                            </div>
                            <a
                              href="#"
                              className="agency-default-btn style4 btn"
                              onClick={handleSubscribeClick}
                            >
                              Choose plan{" "}
                              <i className="flaticon-right-arrow-3"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>

          <img src={shape21} className="pricing-shape-1" alt="Image" />
          <img src={shape22} className="pricing-shape-2" alt="Image" />
        </div>
      </div>
    </>
  );
};

export default Pricing;
